<template>
    <div>
        <v-row dense>
            <v-col cols="12" md="6">
                <v-row dense class="mb-10">
                    <v-col cols="4">
                        <select-pos-model v-model="id_pos" :label="$t('labels.pos')"
                            @change="onPosChange"></select-pos-model>
                    </v-col>
                    <v-col cols="8">
                        <input-qr-scan-model ref="inputQr" v-model="code" :label="label"
                            @keyupEnter="inputScan"></input-qr-scan-model>
                    </v-col>
                    <v-col cols="12">
                        <v-alert color="secondary" text dark class="text-center mb-0">
                            <span class="text-h6">{{ $t('labels.basket') }}: </span>
                            <span class="text-h5">{{ basket.code }}</span>
                        </v-alert>
                        <v-alert color="primary" text dark dense class="fs-16 text-center mb-0"
                            v-if="lastItem && lastItem.sku_uid">
                            UID gần nhất: {{ lastItem.sku_uid }}
                        </v-alert>
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="12" md="6">
                <input-qr-scan-model v-model="goodsFilters.keywords" label="Tìm sản phẩm: Barcode | SKU | Tên"
                    @keyupEnter="getListGoods"></input-qr-scan-model>

                <v-list dense>
                    <div v-for="(item, index) in goodsItems" :key="`${item.id}_${index}`">
                        <v-list-item>
                            <v-list-item-avatar rounded="0">
                                <ImageViewer :url="item.url_images || require('@/assets/common/no-image.jpg')" />
                            </v-list-item-avatar>

                            <v-list-item-content class="pb-0">
                                <v-row no-gutters>
                                    <v-col cols="8">
                                        <v-list-item-subtitle class="font-weight-regular">
                                            <div>
                                                <span class="d-inline-block w-60px">
                                                    {{ $t('labels.barcode') }}:
                                                </span>
                                                <span class="d-inline-block error--text">
                                                    {{ item.customer_goods_barcode }}
                                                </span>
                                            </div>
                                            <div>
                                                <span class="d-inline-block w-60px">
                                                    {{ $t('labels.sku') }}:
                                                </span>
                                                <span class="d-inline-block primary--text">
                                                    {{ item.sku }}
                                                </span>
                                            </div>
                                        </v-list-item-subtitle>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-list-item-subtitle class="font-weight-regular">
                                            <div>
                                                <span class="d-inline-block w-50px">
                                                    {{ $t('labels.available_quantity_1') }}:
                                                </span>
                                                <span class="d-inline-block black--text">
                                                    {{ formatNumber(item.available) }}
                                                </span>
                                            </div>
                                            <div>
                                                <span class="d-inline-block w-50px">
                                                    {{ $t('labels.size') }}:
                                                </span>
                                                <span class="d-inline-block black--text">
                                                    {{ item.size }}
                                                </span>
                                            </div>
                                        </v-list-item-subtitle>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-list-item-subtitle class="font-weight-regular">
                                            <div>
                                                <span class="d-inline-block w-60px">
                                                    {{ $t('labels.name') }}:
                                                </span>
                                                <span class="d-inline-block black--text">
                                                    {{ item.name }}
                                                </span>
                                            </div>
                                            <div>
                                                <span class="d-inline-block w-60px">
                                                    {{ $t('labels.goods_description') }}:
                                                </span>
                                                <span class="d-inline-block black--text">
                                                    {{ item.description }}
                                                </span>
                                            </div>
                                        </v-list-item-subtitle>

                                        <v-list-item-subtitle class="font-weight-regular pb-3">
                                            <div>
                                                <span class="d-inline-block w-60px">
                                                    {{ $t('labels.position') }}:
                                                </span>
                                                <span class="d-inline-block black--text">
                                                    <span v-for="(location, lIndex) in item.locations"
                                                        class="purple--text location-item"
                                                        :key="`l_${item.id}_${index}_${location.cell}_${lIndex}`">
                                                        {{ location.cell }}
                                                        <template v-if="lIndex < (item.locations.length - 1)">
                                                            -</template>
                                                        <span class="locatin-item-quantity">
                                                            [{{ location.quantity }}]
                                                        </span>
                                                    </span>
                                                </span>
                                            </div>
                                        </v-list-item-subtitle>
                                    </v-col>
                                </v-row>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider v-if="index < (goodsItems.length - 1)"></v-divider>
                    </div>
                </v-list>

                <div class="pt-3 mb-5" v-if="goodsTotalPage > 1">
                    <v-pagination v-model="goodsPage" :length="goodsTotalPage" :total-visible="6"></v-pagination>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";

export default {
    name: "Pickup",
    components: {
        ImageViewer: () => import("@/components/common/ImageViewer"),
    },
    data: () => ({
        isLoading: false,
        code: null,
        id_pos: null,
        basket: {},
        lastItem: {},
        goodsItems: [],
        goodsPage: 1,
        goodsTotalPage: 1,
        goodsFilters: {
            keywords: null
        },
    }),
    computed: {
        step() {
            if (this.basket && this.basket.id) {
                return 2
            } else {
                return 1
            }
        },
        label() {
            switch (this.step) {
                case 1:
                    return this.$t('labels.basket_code')
                case 2:
                    if (this.lastItem && this.lastItem.sku_uid) {
                        return this.$t('labels.uid_or_basket_finish')
                    }
                    return this.$t('labels.uid')
                default:
                    return this.$t('labels.code')
            }
        },
    },
    watch: {
        goodsPage() {
            this.getListGoods()
        },
        goodsFilters: {
            handler() {
                this.goodsPage = 1
                this.getListGoods()
            },
            deep: true
        },
    },
    mounted() {
        if (window.me && window.me.working && window.me.working.pickup && window.me.working.pickup_item) {
            const working = JSON.parse(window.me.working.pickup_item)
            this.id_pos = working.id_pos
            this.basket = { ...working.basket }
            this.lastItem = { ...working.lastItem }
        }
    },
    methods: {
        onPosChange() {
            this.focusInputQr()
            this.resetValue()
            this.getListGoods()
        },
        focusInputQr() {
            this.$refs.inputQr.focusInput()
        },

        getListGoods: debounce(function () {
            if (!this.goodsFilters.keywords) {
                return false
            }

            let filters = { ...this.goodsFilters }
            if (filters.keywords) {
                let valueSpl = filters.keywords.split('@') || ['']
                filters = { ...filters, keywords: valueSpl[0] }
            }

            httpClient.post('/goods-list', { ...filters, id_pos: this.id_pos, page: this.goodsPage }).then(({ data }) => {
                this.goodsTotalPage = data.totalPage
                this.goodsItems = [...data.rows].map(row => {
                    const locations = (row.locations && row.locations.split('\n')) || []
                    row.locations = locations.filter((location, index) => index < 5).map(location => {
                        const [cell, quantity] = location.split(' x ')
                        return {
                            cell, quantity
                        }
                    })
                    return row
                })
            })
        }, 1000),

        getLastItem: debounce(function () {
            httpClient.post('/get-last-item-in-basket', { id_basket: this.basket.id, id_pos: this.id_pos }).then(({ data }) => {
                if (data) {
                    this.lastItem = { ...data }
                }
            })
        }, 1000),

        inputScan() {
            if (!this.code) {
                return false
            }

            switch (this.step) {
                case 1:
                    return this.scanBasket()
                case 2:
                    if (this.code == this.basket.code) {
                        return this.resetPickupWorking()
                    } else {
                        return this.scanUid()
                    }

                default:
                    this.$vToastify.error('Wrong!');
            }
        },

        async resetPickupWorking() {
            this.updateWorking({
                type: 'pickup',
                item: null
            })
            const emptyObj = {}
            this.basket = { ...emptyObj }
            this.lastItem = { ...emptyObj }
            this.code = null
        },

        async updatePickupWorking() {
            this.updateWorking({
                type: 'pickup',
                item: JSON.stringify({
                    id_pos: this.id_pos,
                    basket: this.basket,
                    lastItem: this.lastItem
                })
            })
        },

        async scanBasket() {
            if (this.isLoading) {
                this.$vToastify.warning(this.$t('messages.loading'));
                return false
            }
            this.isLoading = true

            try {
                const { data } = await httpClient.post('/get-pickup-basket', {
                    code: this.code
                })
                this.basket = { ...data }
                this.code = null
                this.isLoading = false
                this.getLastItem()
                this.$root.$emit('playSuccessAudio')
            } catch (e) {
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg)
                this.code = null
                this.isLoading = false
                this.$root.$emit('playErrorAudio')
            }
        },

        async scanUid() {
            if (this.isLoading) {
                this.$vToastify.warning(this.$t('messages.loading'));
                return false
            }
            this.isLoading = true

            try {
                const { data } = await httpClient.post('/set-item-to-basket', {
                    id_pos: this.id_pos,
                    code: this.code,
                    basket_code: this.basket.code
                })
                this.lastItem = { ...data }
                this.code = null
                this.isLoading = false
                this.$vToastify.success(this.$t('messages.goods_pickup_success'))
                this.$root.$emit('playSuccessAudio')
                this.updatePickupWorking()
            } catch (e) {
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg)
                this.code = null
                this.isLoading = false
                this.$root.$emit('playErrorAudio')
            }
        },
    }
}
</script>

<style scoped lang="scss">
.location-item {
    position: relative;

    .locatin-item-quantity {
        position: absolute;
        bottom: -14px;
        left: 20%;
        font-size: 9px;
        color: red;
        font-weight: 500;
    }
}
</style>